import React from 'react';
import { connect } from 'react-redux';
import { Col, Button, Popup, PopupHeader } from '@commonsku/styles';
import { createAddAddress, createUpdateAddress, createFinalizeUpdateAddress } from '../../../actions/address';
import { updateOrderAddress } from '../../../actions/order';
import { useForm } from '../../../hooks';
import CreateAddressForm from '../../address/CreateAddressForm';

export function CreateAddressPopup({
    onClosePopup,
    parent_id,
    parent_type='CLIENT',

    onCreate,
    ...props
}) {
    const node = React.useRef();
    const { form, setForm } = useForm({});
    const [ showErrorHighlight, setShowErrorHighlight ] = React.useState(false);
    const [ isFormValid, setFormValid ] = React.useState(false);

    const scrollToTop = () => {
        if (node && node.current) {
            node.current.scrollIntoView({ behavior: 'smooth', block: 'start', });
        }
    };

    const handleSubmit = () => {
        if (!isFormValid) {
            setShowErrorHighlight(true);
            scrollToTop();
            return false;
        }
        onCreate(form);
        onClosePopup();
    };

    return (
        <Popup
            xsStyle="height: 90%; padding-bottom: 50px;"
            mdStyle="max-width: 700px;"
            style={{}}
            onClose={() => onClosePopup()}
            header={
                <PopupHeader xsStyle="flex-wrap: wrap-reverse;" smStyle="flex-wrap: wrap;">
                    <Col xs sm={5} style={{textAlign: 'left', alignSelf: 'center'}}>
                        <span className="title">Add Address</span>
                    </Col>
                    <Col xs sm={7} style={{textAlign: 'right', alignSelf: 'center'}}>
                        <Button style={{ marginRight: 5 }} onClick={onClosePopup}>Close</Button>
                        <Button cta onClick={handleSubmit}>Create</Button>
                    </Col>
                </PopupHeader>
            }
        >
            <CreateAddressForm
                ref={node}
                parent_id={parent_id}
                parent_type={parent_type}
                showError={showErrorHighlight}
                setFormValid={setFormValid}
                setForm={setForm}
            />
        </Popup>
    );
}

const mapStateToProps = (state, ownProps) => ({
    edit_address: ownProps.address_id
        ? state.entities.addresses[ownProps.address_id]
        : null,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
    onAddShopAddress: address => dispatch(createAddAddress({
        ...address,
        parent_id: ownProps.shop_id,
        parent_type: ownProps.shop_parent_type,
        address_type: ownProps.shop_address_type
    })),
    onCreate: address => {
        try {
            dispatch(createAddAddress(address))
            .then(action => {
                if (!ownProps.order_id) {
                    return;
                }
                const address_type = 'shipping_address_id' === ownProps.field ? 'shipping' : 'billing';
                const addressAction = updateOrderAddress(address_type);
                dispatch(addressAction(
                    ownProps.order_id,
                    ownProps.previous_value,
                    action.payload.address.address_id
                ));
            });
        } catch (error) {
            console.log(error);
        }
    },
    onCreateUpdateAddress: (address_id, field, previous_value, value) => {
      dispatch(createUpdateAddress(address_id, field, previous_value, value));
    },
    onCreateFinalizeUpdateAddress: (address_id, field, previous_value) => value => {
      dispatch(createFinalizeUpdateAddress(address_id, field, previous_value, value));
    }
});

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(CreateAddressPopup);
export default ConnectedComponent;
