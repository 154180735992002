import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { orderBy, get, find, filter, size, keys } from 'lodash';
import {
  Row, Col, Button, Dropdown, Tab, TabBar, Badge,
  SidePanel, PanelTileContact, H2, H5, Spinner,
  Link, Text, MarketingStatusIcon
} from '@commonsku/styles';
import { dateStr } from '../../utils';
import { createLoadDepartmentList } from '../../actions';
import { createClearClientState, createLoadParentClientList, createUpdateCompanyClientState } from '../../actions/company_search';
import { createLoadOrderListV2 } from '../../actions/order';
import { createSearchFiles } from '../../actions/file';
import { createUpdateClientAccount, createGetClient } from '../../actions/client';
import { getDeptOptions } from './selectors';
import { RestrictedAccess, Select } from '../helpers';
import { default as ClientProjects } from './ClientProjects';
import { default as ClientDetails } from './ClientDetails';
import { default as Artworks } from './Artworks';
import {
  ConnectedCreateClientPopup  as CreateClientPopup,
  ConnectedCreateContactPopup as CreateContactPopup,
  ConnectedCreateAddressPopup as CreateAddressPopup,
} from './popups';
import { ACTIVITY_TYPE_MAP } from './utils';
import { getContactMP } from '../marketing-platforms/utils';
import { portalSelectors, getPortals } from '../../redux/clientPortals';
import NewProjectPopup from '../project/NewProjectPopup';

const ContactsByDepartment = ({contacts, primary_contact_id, primary_contact, departments, ...props }) => {
  const [department, setDepartment] = React.useState('');
  const contact_depts = React.useMemo(() => (
    keys(contacts).filter(v => v !== null && v !== undefined && v !== 'null')
  ), [contacts]);
  const depts = React.useMemo(() => (
    department === '' || department.toLowerCase() === 'all'
    ? departments
    : departments.filter(v => department === v.value)
  ), [departments]);

  function getMpImageLink(mp) {
    if (mp == 'Mailchimp') {
      return "/images/icons/mailchimp-logo.svg";
    } else if (mp == 'Hubspot') {
      return "/images/icons/hubspot-logo.svg";
    }
    return "";
  }

  return (
    <div>
      <Row>
        <Col xs>
          <Select
            value={department}
            options={[{label: 'All', value: ''}, ...departments.filter(v => contact_depts.includes(v.value))]}
            onChange={e => setDepartment(e.value)}
          />
        </Col>
      </Row>

      {size(contacts) > 0 && size(departments) > 0 ?
        depts.map((d, i) => {
          if (!get(contacts, d.value, null)) {
            return null;
          }
          const list = orderBy(contacts[d.value], ['contact_name'], ['desc']);
          const dept = d.label;

          return <React.Fragment key={i + '_' + d.value}>
            <H5 underlined>{(!dept || dept == 'null') ? '' : dept}</H5>
              {list.length > 0 ?
                <Row>{list.map((v, i) => {
                  const mp = getContactMP(v);
                  const style = {};
                  if (mp.mp) { style['marginBottom'] = 0; }
                  return <Col xs md={6} key={v.contact_id} style={{marginBottom: 15}}>
                    <PanelTileContact
                      key={'contact-' + v.contact_id}
                      style={style}
                      name={
                        <span>
                          <Link as="a" target="_blank" rel="noopener noreferrer" href={`/contact/${v.company_contact_id}`}>{v.contact_first_name + ' ' + v.contact_last_name + ' '}</Link>
                          {primary_contact_id && primary_contact_id === v.contact_id ? <Badge>Primary</Badge> : ''}
                        </span>
                      }
                      position={v.contact_position}
                      email={v.display_email ? v.contact_email : ''}
                      phones={v.phones}
                  />
                  <Text>
                    {v.mp_contact == 'yes' ? <ImageBadge imgSrc={getMpImageLink(mp.mp)} /> : null}
                    {v.contact_no_marketing == '1' ? <ImageBadge><MarketingStatusIcon /></ImageBadge> : null}
                  </Text>
                </Col>;
                })}</Row>
            : null}
          </React.Fragment>;
        })
      : null}
    </div>
  );
};

const ImageBadge = ({ imgSrc=null, children, badgeColor='grey' }) => {
  return (imgSrc || children ?
    <Badge style={{marginLeft: 2, padding: 5, height: 32, width: 32, borderRadius: 32}} color={badgeColor}>
      {children ? children : <img src={imgSrc} height="20px" width="20px" />}
    </Badge>
  : null);
};

const Addresses = ({ addresses=[] }) => {
  addresses = addresses.filter(v => v && (v.address_name
    || v.address_company
    || (v.address_line_1 || v.address_line_2 || v.address_line_3 || v.address_line_4)
    || (v.address_city || v.address_state || v.address_country)));

  function getTextlengthStyles(text_length) {
    return {lineHeight: text_length > 30 ? '1.5em' : '0.8em'};
  }

  return (
    <Row>
      {addresses.map((v, i) =>
        <Col xl={6} lg={6} sm={6} xs padded key={i + '_' + v.address_id} style={{alignSelf: 'flex-start',}}>
          {v.address_name && <p style={getTextlengthStyles(v.address_name.length)}><strong>{v.address_name}</strong></p>}
          {v.address_company && <p style={getTextlengthStyles(v.address_company.length)}>{v.address_company}</p>}
          {v.address_line_1 && <p style={getTextlengthStyles(v.address_line_1.length)}>{v.address_line_1}</p>}
          {v.address_line_2 && <p style={getTextlengthStyles(v.address_line_2.length)}>{v.address_line_2}</p>}
          {/* {v.address_line_3 && <p style={getTextlengthStyles(v.address_line_3.length)}>{v.address_line_3}</p>} */}
          {/* {v.address_line_4 && <p style={getTextlengthStyles(v.address_line_4.length)}>{v.address_line_4}</p>} */}
          {(v.address_city || v.address_state || v.address_country) &&
            <p style={getTextlengthStyles(((v.address_city || '') + (v.address_state || '') + (v.address_country || '')).length + 5)}>
              {v.address_city + ','} {v.address_state}{v.address_country && ', ' + v.address_country}
            </p>}
          {v.address_postal && <p style={getTextlengthStyles(v.address_postal)}>{v.address_postal}</p>}
        </Col>
      )}
    </Row>
  );
};

export function ClientSidePanel({
  identity,
  client_id,
  client={},
  updatePanelSettings,
  filterOptions,
  updateClient,
  getClient,
  hasAccess,
  clearClient,
  hasCapabilities,
  isAdmin,
  ...props
}) {
  const [showPopup, setShowPopup] = React.useState(false);
  const [selectTabIndex, setSelectTabIndex] = React.useState(props.settings.tab_index || 0);

  const dispatch = useDispatch();
  const portalState = useSelector(state => state.clientPortals);
  const portals = portalSelectors.selectAll(portalState);

  const primary_contact = React.useMemo(() => (
    client && client.contacts && client.primary_contact_id && find(client.contacts, (v) => v.contact_id === client.primary_contact_id)
  ), [client]);
  const addresses = get(client, 'addresses', []);

  const perms = props.accountAccess();
  const orderPerms = props.hasOrderAccess();

  useEffect(() => {
    if (client_id) {
      if (selectTabIndex === 2) {
        props.loadOrders(client_id);
      } else if (selectTabIndex === 3) {
        props.loadParentClients();
      } else if (selectTabIndex === 4) {
        props.loadArtworks(client_id);
      }
    }
  }, [client_id]);

  useEffect(() => {
    dispatch(getPortals());
  }, [dispatch]);

  const last_activity = React.useMemo(() => {
    if (client.last_activites && Object.keys(client.last_activites).length && client.last_activites[client.client_id]) {
      const last_activity = client.last_activites[client.client_id];
      const d = last_activity.activity_date && last_activity.activity_date.indexOf('0000-00-00') === -1
            ? new Date(last_activity.activity_date.split(" ")[0])
            : '';
      const activity_type = ACTIVITY_TYPE_MAP[last_activity.activity_type];

      return {
        'date': dateStr(d) || '',
        'activity_type': activity_type,
        'val': !activity_type ? '' : activity_type.href === 'none'
                ? activity_type.val
                : activity_type.val + (last_activity.activity_details ? ' #' + last_activity.activity_details : ''),
        'link': !activity_type ? '' : activity_type.href === 'none' ? false : last_activity.activity_type.replace(' ', '_').toLowerCase() + '.php?id=' + last_activity.activity_id,
      };
    }

    return null;
  }, [client]);

  function createPopup() {
    const sharedProps = {
      onClosePopup: () => {
        setShowPopup(false);
        getClient(client_id);
      },
      parent_id: client_id,
      parent_type: 'CLIENT',
    };

    if (showPopup === 'contact') {
      return <CreateContactPopup {...sharedProps} />;
    } else if (showPopup === 'address') {
      return <CreateAddressPopup {...sharedProps} />;
    } else if (showPopup === 'client') {
      return <CreateClientPopup {...sharedProps} />;
    } else if (showPopup === 'artwork') {
      //
    } else if (showPopup === 'new_project') {
      return <NewProjectPopup client_id={client_id} onClose={() => setShowPopup(false)} {...sharedProps} />;
    }
  }

  const toggleEdit = () => {
    if (props.settings.editDetails === true) {
      updateClient(client_id || client.client_id, {...client, update_remote: true,});
    } else {
      if (selectTabIndex !== 3) {
        updatePanelSettings({ tab_index: 3, });
        setSelectTabIndex(3);
        props.loadParentClients();
      }
    }

    updatePanelSettings({editDetails: !props.settings.editDetails});
  };

  const tabs = () => [
    {
      label: 'Contacts',
      onClick: () => {
        updatePanelSettings({tab_index: 0, });
      },
      content: props._loading
                ? <div style={{marginLeft: 'auto', marginRight: 'auto'}}><Spinner /></div>
                : <ContactsByDepartment access={props.settings.access === true}
                    primary_contact={primary_contact}
                    primary_contact_id={client && client.primary_contact_id}
                    secondary_contact_id={client && client.secondary_contact_id}
                    tertiary_contact_id={client && client.tertiary_contact_id}
                    contacts={client && client.contacts_by_department}
                    // contacts={contacts}
                    departments={props.departments} />
    },
    {
      label: 'Addresses',
      onClick: () => {
        updatePanelSettings({tab_index: 1,});
      },
      content: props._loading
                ? <div style={{marginLeft: 'auto', marginRight: 'auto'}}><Spinner /></div>
                : <Addresses access={props.settings.access === true} addresses={addresses} />
    },

    {
      label: 'Projects',
      onClick: () => {
        updatePanelSettings({tab_index: 2,});
        props.loadOrders(client_id);
      },
      content: <ClientProjects access={props.settings.access === true} client_id={client_id} parent_type='CLIENT' />,
    },

    {
      label: 'Details',
      onClick: () => {
        updatePanelSettings({tab_index: 3,});
        props.loadParentClients();
      },
      content: <ClientDetails
                  identity={identity}
                  access={props.settings.access === true || client.sales_rep_id === identity.user_id}
                  perms={perms}
                  editDetails={props.settings.editDetails}
                  toggleEdit={toggleEdit}
                  client={client}
                  filterOptions={filterOptions}
                  all_tags_labels={props.all_tags_labels}
                  autofocusField={props.settings.autofocusField} />,
    },

    {
      label: 'Files',
      onClick: () => {
        updatePanelSettings({tab_index: 4});
        props.loadArtworks(client_id);
      },
      content: <Artworks access={perms === true} parent_id={client_id} parent_type='CLIENT' />,
    },
  ];

  return (
    <React.Fragment>
      {createPopup()}
      <SidePanel
        style={{paddingBottom: 15, overflow: 'auto',}}
        visible={client !== null && client !== undefined && Object.keys(client).length > 0}
        header={
          <Row>
            <Col xs style={{ textAlign: 'right', }}>
              <Dropdown sm={'width: 100%; margin-top: 10px;'} style={{marginRight: '10px', zIndex: 105}} items={[
                perms && {onClick: () => setShowPopup('contact'), content: 'New Contact'},
                perms && {onClick: () => setShowPopup('address'), content: 'New Address'},
                orderPerms && {onClick: () => setShowPopup('new_project'), content: 'New Project'},
                perms && {
                  onClick: toggleEdit,
                  content: props.settings.editDetails ? 'Save' : 'Edit Details'
                },
                {onClick: () => window.open(`/send_email.php?company_type=CLIENT&parent_type=CLIENT&parent_id=${client_id || client.client_id}`, '_blank'), content: 'Send Email'},
                {onClick: () => window.open(`/client.php?id=${client_id || client.client_id}`, '_blank'), content: 'Client Page'},
                (hasCapabilities(['HAS-CLIENT-PORTALS']) && portals.length > 0) && {onClick: () => window.open(`/client/${client_id || client.client_id}/portal/`, '_blank'), content: 'View Client Portal'},
              ]} />
              <Button onClick={() => props.onClosePanel()}>Close</Button>
            </Col>
            <Col xs><H2>{client && client.client_name}</H2></Col>
            <Col xs>
              {last_activity ? <p style={{fontSize: '1rem', margin: '0'}}>
                <strong>Last activity: </strong>
                {last_activity.date + ' '}
                {!last_activity ? '' :
                  last_activity.link === false ? last_activity.val :
                    <a target="_blank" rel="noopener noreferrer" href={last_activity.link}>{last_activity.val}</a>}
              </p> : <i>No activity found.</i>}
            </Col>
          </Row>
        }
      >
        {props.loading && props.loading.client === true
          ? <div style={{marginLeft: 'auto', marginRight: 'auto'}}><Spinner /></div>
          : !(props.settings.access === true) && hasCapabilities(['LIMITED-ACCESS', 'RESTRICTED-ACCESS'], true)
            ? <RestrictedAccess />
            : <div>
                <TabBar padded>
                  {tabs().map((v, i) => (
                    <Tab style={{padding: '1rem 8px'}} key={i}
                      selected={i == selectTabIndex}
                      onClick={() => {
                        setSelectTabIndex(i);
                        let callback = v.onClick;
                        if(callback) { callback(); }
                      }}>{v.label}</Tab>
                  ))}
              </TabBar>
              {tabs()[selectTabIndex].content}
            </div>}

      </SidePanel>
    </React.Fragment>
  );
}

const mapStateToProps = (state, ownProps) => {
  return {
    identity: state.identity,
    user_id: state.identity.user_id,
    client: (state.entities.company_search || {}).client || {},
    loading: state.display.loading,
    departments: getDeptOptions(state),
    _loading: !state.entities.company_search || (state.entities.company_search && !state.entities.company_search.client),
    panel_settings: ownProps.settings
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClient: (client_id) => {
      dispatch(createGetClient(client_id, { with_last_activity: true, with_sales_report: true }));
    },
    clearClient: () => dispatch(createClearClientState()),
    updateClient: (client_id, data) => {
      dispatch(createUpdateClientAccount(client_id, data));
      dispatch(createUpdateCompanyClientState(client_id, {
        sales_target: data.sales_target,
        client_name: data.client_name,
        client_rep_user_id: data.sales_rep_id,
      }));
    },
    loadArtworks: (parent_id, parent_type='CLIENT', search={}) =>
      dispatch(createSearchFiles(parent_id, parent_type, search)),
    loadDepartments: (parent_type='CLIENT') => dispatch(createLoadDepartmentList(parent_type)),
    loadOrders: (
      client_id,
      options={
        search: '',
        ignore_id: '',
        order_type: 'OPPORTUNITY',
        'max-results': 20,
        'start-index': 0,
      }
    ) => dispatch(createLoadOrderListV2(client_id, options)),
    loadParentClients: (options={}) => dispatch(createLoadParentClientList(options)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientSidePanel);
